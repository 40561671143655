import { createFriendlyApiError, tryHandleError, wrappedFetchWithResponse } from "../Shared/Common";

const SubmitRsvp = async (rsvpRequest) => {
  const data = {
    id: rsvpRequest.id, 
    rsvpId: rsvpRequest.rsvpId,
    hasReplied: true,
    email: rsvpRequest.email,
    attending: rsvpRequest.attending,
    comments: rsvpRequest.comments,
    guests: rsvpRequest.guests.map(a => {
      return {
        Id: isNaN(a.id) ? a.id : '',
        FirstName: a.firstName,
        LastName: a.lastName,
        IsChild: a.isChild,
        DietaryRequirements: a.dietary
      }
    })
  }

  let res;
  try {
    res = await fetch('https://rsvp-prod.azurewebsites.net/Rsvp/', {
      method: 'PUT',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    });
  }
  catch (e) {
    console.error('failed to fetch', e)
    const [, error] = createFriendlyApiError('connection-failed')
    return error;
  }

  const [errored, error] = await tryHandleError(res);
  if (errored) {
    return error
  }

  return {}
}

const FetchRsvp = async (rsvpId) => {
  return await wrappedFetchWithResponse(`https://rsvp-prod.azurewebsites.net/Rsvp/${rsvpId}`);
}

export { SubmitRsvp, FetchRsvp };