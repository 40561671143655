import {useState} from "react";
import RadioInput from '../../../components/RadioInput/RadioInput';
import TextInput from '../../../components/TextInput/TextInput';
import Guests from "./Guests";
import {SubmitRsvp, FetchRsvp} from "../../../services/RsvpService/RsvpService";
import Button from "../../../components/Button/Button";

const RsvpForm = () => {
  const [idForm, setIdForm] = useState({
    rsvpId: ''
  });
  const [retrieving, setRetrieving] = useState(false);
  const [retrieveResult, setRetrieveResult] = useState({
    Status: 'unRetrieved',
    Errors: [],
    Message: null
  })
  const handleRsvpRetrieve = async (ev) => 
  {
    ev.preventDefault();
    setRetrieveResult({
      Status: null,
      Message: null,
      Errors: []
    });
    
    setRetrieving(true);
    const res = await FetchRsvp(idForm.rsvpId);
    setRetrieving(false);
    var sortOrder = 0;
    if (!res.error) {
      setRetrieveResult({Status: 'ok'});
      setForm({
        nextGuestOrder: res.guests.length,
        id: res.id,
        rsvpId: res.rsvpId,
        email: res.email ?? '',
        attending: res.attending,
        guests: res.guests.map(a =>{
          var guest = { 
            sortOrder: sortOrder,
            id: a.id,
            firstName: a.firstName,
            lastName: a.lastName,
            isChild: a.isChild,
            dietary: a.dietaryRequirements ?? ''
          }
          sortOrder++;
          return guest;
        }),
        comments: res.comments ?? ''
      });
      return;
    } else {
      if (res.status === 404)
      {
        setRetrieveResult({
          Status: res.error,
          Message: 'Unable to find an RSVP for the given id code.'
        });

      }
      else
      {
      setRetrieveResult({
        Status: res.error,
        Message: 'Sorry, something has gone wrong. Please try again.'
        });
      }
    }
  }
  
  const [form, setForm] = useState({
    nextGuestOrder: 0,
    id: '00000000-0000-0000-0000-000000000000',
    rsvpId: '',
    email: '',
    attending: null,
    guests: [{
      sortOrder: 0,
      id: '00000000-0000-0000-0000-000000000000',
      firstName: '',
      lastName: '',
      isChild: false,
      dietary: '',
    }],
    comments: ''
  })
  const setEmail = (ev) => {
    setForm({...form, email: ev.target.value})
    let valid = true;
    if (ev.target.value === '') {
      valid = false;
    }
    setEmailValid(valid);
  }
  const [attendingValid, setAttendingValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitResult, setSubmitResult] = useState({
    Status: 'unsubmitted',
    Errors: [],
    Message: null
  })
  const setAttending = (val) => {
    setAttendingValid(true);
    setForm({...form, attending: val})
  }
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setEmailValid(true);
    setSubmitResult({
      Status: null,
      Message: null,
      Errors: []
    });

    if (form.email === '') {
      setEmailValid(false);
    }
    if (form.attending === null) {
      setAttendingValid(false);
    }

    if (form.email === '' || form.attending === null) return;

    setSubmitting(true);
    const res = await SubmitRsvp(form);
    setSubmitting(false);
    if (!res.error) {
      setSubmitResult({
        Status: 'ok'
      })
      return;
    }

    if (res.error === 'validation-error') {
      setSubmitResult({
        Status: res.error,
        Errors: res.details.errors,
        Message: 'Sorry, something has gone wrong'
      })
      res.details.errors.forEach(e => {
        switch (e.propertyName) {
          case 'Attending':
            setAttendingValid(false);
            break;
          case 'Email':
            setEmailValid(false);
            break;
          default:
            break;
        }
      })
    } else {
      setSubmitResult({
        Status: res.error,
        Message: 'Sorry, something has gone wrong. Please try again.'
      })
    }
  }

  return (
    <>
      <section className='bg-wedding-green text-white bg-center min-h-[66vh] pt-32 flex items-center justify-center text-center'>
        <div className='py-[6.6vmax]'>
          <h1 className='text-white text-7xl py-6'>RSVP</h1>
          <p>We look forward to seeing you!</p>
        </div>
      </section>
      <section className='bg-white text-marriage-green p-[4vw] w-full'>
        <div className='w-[90%] lg:w-1/2 m-auto'>
          <div className='text-center pb-[4vw]'>
            <h2 className='text-4xl'>David and Adrienne's Wedding</h2>
            <p className='text-lg py-4'>Please RSVP by <nobr>30th April 2024</nobr></p>
            <p className='text-lg py-4'>By RSVP-ing, you are confirming you will attend both the ceremony and the reception.
              If you can only come to one of the events, please let us know in the comment box.</p>
            <p className='text-lg py-4'>Formal dress code. (i.e. men: suit and tie, ladies: evening dress)</p>
          </div>
          {
            submitResult.Status === 'ok' ? 
            <>
              <p className='text-center'>
                Thank You!
              </p>
            </> :
            retrieveResult.Status === 'ok' ?
            <form className='w-full font-sans' onSubmit={handleSubmit}>
              <div className='w-full grid grid-cols-6 gap-y-8 gap-x-4'>
                <TextInput name='email' label='Email *' placeholder='eg. email@gmail.com' value={form.email}
                           type='email'
                           onChange={setEmail}
                           className='col-span-6'
                           valid={emailValid}/>
                <div className='col-span-6'>
                  <fieldset>
                    <p className={`text-sm mb-1 ${!attendingValid ? 'text-red-700' : undefined}`}>Will you be attending?
                      *</p>
                    <RadioInput name='attending' value='yes' label='Yes' checked={form.attending === true}
                                onChange={() => setAttending(true)}
                                valid={attendingValid}/>
                    <RadioInput name='attending' value='no' label='No' checked={form.attending === false}
                                onChange={() => setAttending(false)}
                                valid={attendingValid}/>
                  </fieldset>
                </div>
                {!form.attending ? undefined :
                  <>
                    <div className='col-span-6'>
                      <p className='text-sm mb-1'>Please enter all guests who will be attending below, <b>including yourself.</b></p>
                    </div>
                    <Guests form={form} setForm={setForm}/>
                  </>
                }
                <TextInput name='comments' label='Questions or Comments (optional)' placeholder='' value={form.comments}
                           onChange={(ev) => setForm({...form, comments: ev.target.value})} className='col-span-6'/>
                <div className='col-span-6 text-center text-[#B91C1C]'>
                  <p>
                    {submitResult.Message}
                  </p>
                  {submitResult.Errors ? submitResult.Errors.map(a => <p
                    className='text-sm m-2'>{a.errorMessage}</p>) : undefined}
                </div>
                <div className='col-span-6 text-center'>
                  <SubmitButton submitting={submitting}/>
                </div>
              </div>
            </form> :
            <form className='w-full font-sans' onSubmit={handleRsvpRetrieve}>
              <div className='w-full grid grid-cols-6 gap-y-8 gap-x-4'>
                <TextInput name='rsvpId' 
                           label='RSVP Id' 
                           placeholder='This can be found on your invitation' 
                           value={idForm.rsvpId}
                           onChange={(ev) => setIdForm({...idForm, rsvpId: ev.target.value})}
                           className='col-span-6'/>
                <div className='col-span-6 text-center'>
                  <RetrieveButton retrieving={retrieving}/>
                </div>
                <div className='col-span-6 text-center text-[#B91C1C]'>
                  <p>
                    {retrieveResult.Message}
                  </p>
                  {retrieveResult.Errors ? retrieveResult.Errors.map(a => <p
                    className='text-sm m-2'>{a.errorMessage}</p>) : undefined}
                </div>
              </div>
            </form>
          }
        </div>
      </section>
    </>
  )
}

const SubmitButton = ({submitting}) => {
  return <Button className='p-4 w-1/3' disabled={submitting} type='submit'>
    {submitting ?
      <>Submitting...</> :
      <>Submit</>
    }
  </Button>
}

const RetrieveButton = ({retrieving}) => {
  return <Button className='p-4 w-1/3' disabled={retrieving} type='submit'>
    {retrieving ?
      <>Retrieving...</> :
      <>Retrieve</>
    }
  </Button>
}

export default RsvpForm;