import InputLabel from "../InputLabel/InputLabel";

export const RadioInput = ({name, value, label, checked, onChange, valid}) => {
  if (valid === undefined) {
    valid = true;
  }
  return (
    <div className='flex items-center py-1'>
      <input name={name} id={name + '-' + value} type='radio' value={value} checked={checked} onChange={onChange}
             className={`w-4 h-4 checked:text-marriage-green focus:ring-marriage-green ${!valid ? 'border-red-700' : 'border-marriage-green'} cursor-pointer`}/>
      <InputLabel htmlFor={name + '-' + value} className={`ml-3 cursor-pointer ${!valid ? 'text-red-700' : undefined}`}>{label}</InputLabel>
    </div>
  )
}

export default RadioInput;