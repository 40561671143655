import {useState} from "react";
import {TextInput} from "../../../components/TextInput/TextInput";

export const Guests = ({form, setForm}) => {
  const [nextGuestId, setNextGuestId] = useState(form.nextGuestOrder)
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [lastNameValid, setLastNameValid] = useState(true);

  const addGuest = () => {
    const emptyGuest = {
      sortOrder: nextGuestId,
      id: '00000000-0000-0000-0000-000000000000',
      firstName: '',
      lastName: '',
      isChild: false,
      dietary: ''
    };
    setForm({
      ...form,
      guests: [...form.guests, emptyGuest]
    })
    setNextGuestId(nextGuestId + 1)
  }
  const removeGuest = (sortOrder) => {
    setForm({
      ...form,
      guests: form.guests.filter((v) => v.sortOrder !== sortOrder)
    })
  }
  const firstNameHandler = (ev) => {
    const updatedGuest = form.guests.find(g => `guest-first-name-${g.sortOrder}` === ev.target.name)
    updatedGuest.firstName = ev.target.value;

    const guests = [updatedGuest, ...form.guests.filter(g => `guest-first-name-${g.sortOrder}` !== ev.target.name)]

    setForm({
      ...form,
      guests: guests.sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      })
    })

    let valid = true;
    if (ev.target.value === '') {
      valid = false;
    }
    setFirstNameValid(valid);
  }
  const lastNameHandler = (ev) => {
    const updatedGuest = form.guests.find(g => `guest-last-name-${g.sortOrder}` === ev.target.name)
    updatedGuest.lastName = ev.target.value;

    const guests = [updatedGuest, ...form.guests.filter(g => `guest-last-name-${g.sortOrder}` !== ev.target.name)]

    setForm({
      ...form,
      guests: guests.sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      })
    })

    let valid = true;
    if (ev.target.value === '') {
      valid = false;
    }
    setLastNameValid(valid);
  }
  const dietaryHandler = (ev) => {
    const updatedGuest = form.guests.find(g => `guest-dietary-${g.sortOrder}` === ev.target.name)
    updatedGuest.dietary = ev.target.value;

    const guests = [updatedGuest, ...form.guests.filter(g => `guest-dietary-${g.sortOrder}` !== ev.target.name)]

    setForm({
      ...form,
      guests: guests.sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      })
    })
  }

  return (
    <>
      <div className='col-span-6 grid grid-cols-6 gap-4'>
        {form.guests.map((a, i) =>
          <div key={a.sortOrder} className='col-span-6 flex flex-row flex-wrap items-end gap-x-4 md:gap-y-0'>
            <TextInput name={`guest-first-name-${a.sortOrder}`}
                          label='First Name *' 
                          placeholder='eg. Adrienne' 
                          value={a.firstName}
                          className='col-span-6 sm:col-span-3'
                          onChange={firstNameHandler}
                          valid={firstNameValid}/>
            <TextInput name={`guest-last-name-${a.sortOrder}`} 
                          label='Last Name *' 
                          placeholder='eg. Owen' 
                          value={a.lastName}
                          className='col-span-6 sm:col-span-3'
                          onChange={lastNameHandler}
                          valid={lastNameValid}/>
            <TextInput name={`guest-dietary-${a.sortOrder}`} 
                          label='Any food restrictions? (optional)'
                          placeholder='eg. Vegetarian, Vegan, Allergies'
                          value={a.dietary}
                          className='col-span-6'
                          onChange={dietaryHandler}/>
            {i === 0 && form.guests.length === 1 ? undefined :
              <RemoveGuestButton removeGuest={removeGuest} guest={a}/>}
          </div>
        )}
        <div className='col-span-6 text-sm mt-2'>
          <AddGuestButton addGuest={addGuest}/>
        </div>
      </div>
    </>
  )
}

const AddGuestButton = ({addGuest}) =>
  <button type='button' onClick={addGuest}
          className='border rounded-sm p-2 flex items-center hover:border-marriage-green'>
    <div className='inline-flex'>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className='stroke-marriage-green w-5 h-5'
           stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"/>
      </svg>
    </div>
    <span className='pl-2'>Add another guest</span>
  </button>

const RemoveGuestButton = ({guest, removeGuest}) =>
  <button type='button' onClick={() => removeGuest(guest.sortOrder)}
          className='border rounded-sm w-[42px] h-[42px] p-2 hover:border-marriage-green shadow-sm'>
    <span className='sr-only'>Remove guest</span>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         className='stroke-marriage-green'
         stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className=''
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
    </svg>
  </button>


export default Guests;