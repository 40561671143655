import CeremonyImage from '../../assets/home/ceremony_image.png'
import {NavLink} from "react-router-dom";
import WebLink from "../../components/Link/WebLink";

function HomeApp() {
  return (
    <main>
      <section
        className='h-screen bg-marriage-green w-full bg-top text-white flex justify-center items-center text-center'>
        <div>
          {/* Redundant font-garamond here to stop tailwind from removing it for optimized bundle */}
          <p className='font-garamond'>We're getting married!</p>
          <h1 className='text-7xl py-6'>David & Adrienne</h1>
          <p>1<sup>st</sup> June 2024</p>
        </div>
      </section>
      <section className='bg-parchment sm:h-screen w-full px-[4vw] py-[4vw] text-center flex justify-between'>
        <div className='flex flex-col md:flex-row items-center justify-around gap-6 px-[4vw] py-[6vw]'>
          <img src={CeremonyImage} alt="David & Adrienne"
               className='w-full max-h-screen md:max-w-[45%] object-contain flex-initial'/>
          <div className='text-marriage-green'>
            <h1 className='text-6xl py-6'>Ceremony & Reception</h1>
            <h2 className='text-3xl py-6'>3:00 PM</h2>
            <h3 className='text-2xl py-3 font-bold'>Inglewood Estate</h3>
            <p className='py-1'><WebLink href="https://maps.app.goo.gl/QvEaebjVRngpgg5S7" isExternal={true}>130 Eltham-Yarra Glen Rd, Kangaroo Ground VIC 3095</WebLink></p>
          </div>
        </div>
      </section>
      <section
        className='bg-marriage-green h-screen w-full px-[4vw] py-[4vw] text-center flex justify-between'>
        <div className='hidden lg:flex flex-grow items-center px-20'>
          <hr className='hr flex-grow border-parchment'/>
        </div>
        <div className="flex flex-grow lg:flex-initial items-center">
          <div className='m-auto'>
            <h1 className='text-5xl py-6 text-parchment'>See how it all started</h1>
            <div>
              <NavLink to='/story'
                       className='inline-block bg-white px-8 py-4 text-black text-xl transition hover:bg-marriage-green hover:text-white'>Our Story</NavLink>
            </div>
          </div>
        </div>
        <div className='hidden lg:flex flex-grow items-center px-20'>
          <hr className='hr flex-grow border-parchment'/>
        </div>
      </section>
      <section className={`bg-parchment h-screen px-[4vw] py-[4vh]`}>
        <div
          className='text-center text-marriage-green items-center flex justify-center flex-col relative h-full'>
          <p>Join Us</p>
          <h1 className='text-6xl py-8'>We hope you can make it!</h1>
          <NavLink to='/rsvp'
                   className='inline-block bg-marriage-green text-lg text-white py-4 px-6 mt-4 transition hover:bg-white hover:text-black'>RSVP</NavLink>
        </div>
      </section>
    </main>
  )
}

export default HomeApp