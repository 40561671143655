function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Button = ({type, disabled, children, className, onClick}) => {
  if (disabled === undefined || disabled === false)
    disabled = false;

  if (!type || type === '')
    type = 'button'

  return (<button type={type}
                  className={classNames(className, 'transition text-marriage-green border border-marriage-green hover:bg-marriage-green hover:text-white disabled:bg-gray-100 disabled:text-marriage-green')}
                  disabled={disabled}
    onClick={onClick}>
    {children}
  </button>)
}

export default Button;