import {useRef} from "react";
import Flickity from 'react-flickity-component'

import BlurbImage from '../../assets/our story/Blurb_Image.jpg'
import Carousel_1 from '../../assets/our story/Story_1.jpg'
import Carousel_2 from '../../assets/our story/Story_2.jpg'
import Carousel_3 from '../../assets/our story/Story_3.jpg'
import Carousel_4 from '../../assets/our story/Story_4.jpg'
import Carousel_5 from '../../assets/our story/Story_5.jpg'
import Carousel_6 from '../../assets/our story/Story_6.jpg'
import Carousel_7 from '../../assets/our story/Story_7.jpg'


export default function Story() {
  const flkty = useRef(null);

  function next() {
    flkty.current.next(true);
  }

  function prev() {
    flkty.current.previous(true);
  }

  return (
    <>
      <section className='flex items-center mt-32 h-[25vh] md:h-[33vh] justify-center items-center text-center'>
        <h1 className='text-white text-4xl md:text-5xl'>How it all began</h1>
      </section>
      <section className='text-white md:min-h-screen px-[10vw] pt-[5vh] pb-[10vh]'>
        <div className='flex flex-col xl:flex-row justify-center gap-x-20'>
          <img src={BlurbImage}
              alt="David and Adrienne"
              className='top-0 xl:w-1/2 max-w-2xl h-full min-h-[31rem] max-h-[50vw] xl:max-h-full object-cover'/>
          <div>
            <p className='py-4'>—</p>
            <p>David and Adrienne met in the big melting pot of RMIT University in 2014. Studying pretty different things, 
              with mostly different social circles; it was a chance meeting during a very brief game of Magic: The Gathering 
              (if you don't know Magic, it's a card game.)</p>
            <p className='pt-4'>This slowly snowballed into becoming friends and gaming buddies until they finally started 
              dating in March 2015.</p>
            <p className='pt-4'>Fast forward through to moving out, travelling, getting a house, and two trash cats 
              (Finch & Chips); it's now time to have The Big Party.</p>
          </div>
        </div>
      </section>
      <section className='text-marriage-green bg-parchment pb-[10vh]'>
        <div className='px-[10vw] py-[10vh] text-center'>
          <h2 className='my-[10vh] text-5xl'>And the rest is history</h2>
        </div>
        <div className='overflow-hidden'>
          <Flickity
            flickityRef={c => flkty.current = c}
            options={{
              wrapAround: true,
              pageDots: false,
              prevNextButtons: false,
              initialIndex: 2,
              lazyLoad: 2
            }}
            static
          >
            <img data-flickity-lazyload-src={Carousel_1}
                 alt="David and Adrienne"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_2}
                 alt="David and Adrienne"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_3}
                 alt="Adrienne runs from her worst nightmare"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_4}
                 alt="Just David thinking of cats"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_5}
                 alt="David and Adrienne"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_6}
                 alt="David and Adrienne"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
            <img data-flickity-lazyload-src={Carousel_7}
                 alt="David runs on coffee"
                 className='w-fit sm:w-auto h-[60vh] object-cover'/>
          </Flickity>
          <div className='flex justify-center gap-10 py-6 sr'>
            <button type='button' onClick={prev}>
              <span className='sr-only'>Previous image</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"/>
              </svg>
            </button>
            <button type='button' onClick={next}>
              <span className='sr-only'>Next image</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3"/>
              </svg>
            </button>
          </div>
        </div>
      </section>
    </>
  )
}