import Nav from "./components/Header/Nav";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home/Home";
import RsvpForm from "./views/Rsvp/RsvpForm/RsvpForm";
import Story from "./views/Story/Story";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet, useLocation, useNavigationType
} from "react-router-dom";
import React, {useEffect} from "react";

function App() {
  return (
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path='/' element={<Layout/>}>
              <Route index element={<Home/>}/>
              <Route path='story' element={<Story/>}/>
              <Route path='rsvp' element={<RsvpForm/>}/>
              <Route path='*' element={<NoMatch/>}/>
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
  );
}

function Layout() {
  return (
    <>
      <Nav/>
      <Outlet/>
      <Footer/>
    </>
  )
}

function NoMatch() {
  return (
    <div className='bg-[#e0e0db] w-screen h-[66vh] flex flex-col justify-center items-center'>
      <h1 className='text-6xl py-6'>Nothing here!</h1>
      <p className='underline hover:text-white'><a href='/'>Go home?</a></p>
    </div>
  )
}

const ScrollToTop = (props) => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    if (navigationType !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [location, navigationType]);

  return <>{props.children}</>
};

export default App;
