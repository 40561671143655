import logo from '../../assets/Union_logo.png'
import {Disclosure, Transition} from '@headlessui/react'
import {NavLink} from 'react-router-dom'
import {Rotate as Hamburger} from 'hamburger-react'
import {forwardRef} from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NavItem = ({children, to, className, innerRef, ...props}) => {
  return (
    <>
    <NavLink
      to={to}
      ref={innerRef}
      {...props}
      className={({isActive}) =>
        classNames(isActive ? 'underline underline-offset-8 block' : 'block', className)
      }>
      {children}
    </NavLink>
    </>
  )
}

const MobileRsvpCta = ({innerRef, ...props}) => {
  return (
    <NavLink
      to='rsvp'
      ref={innerRef}
      {...props}
      className='block bg-marriage-green m-auto w-[55vw] text-center py-4 text-xl text-white'>
      RSVP
    </NavLink>
  )
}

export default function Nav() {
  const MyFieldRef = forwardRef((p, ref) => <NavItem innerRef={ref} {...p} />)
  const MobileRsvpCtaRef = forwardRef((p, ref) => <MobileRsvpCta innerRef={ref} {...p} />)

  return (
    <Disclosure
      as='nav'
      className='fixed top-0 w-screen text-base text-white z-10'>
      {({open}) => {
        document.body.style.overflow = ''
        if (open) {
          document.body.style.overflow = 'hidden'
        }

        return (
          <div className='w-full'>
            <div className='flex items-center flex-wrap py-9 px-[8vw] justify-between'>
              <div id='nav-background-gradient' className='absolute left-0 right-0 top-0 bottom-0 bg-gradient-to-b from-black/50 to-transparent z-30' />
              {/* Desktop only */}
              <div className='hidden md:flex relative z-50 w-1/3'>
                <NavItem to='/' className='mr-4 text-lg whitespace-nowrap'>When & Where</NavItem>
                <NavItem to='story' className='mr-4 text-lg whitespace-nowrap'>Our Story</NavItem>
              </div>
              <div className='relative z-50 md:w-1/3'>
              <NavLink to={'/'}>
                <img src={logo} alt='logo' className='max-h-14 md:max-h-16 md:m-auto max-w-full '/>
              </NavLink>
              </div>
              <Disclosure.Button className='md:hidden relative z-50'>
                <Hamburger label="Show menu" toggled={open} size={20}/>
              </Disclosure.Button>
              <NavLink
                to={'/rsvp'}
                className='hidden md:block z-50 w-1/3 text-right'>
                <span className='bg-white hover:bg-marriage-green text-black hover:text-white py-3 px-6 text-lg transition'>RSVP</span>
              </NavLink>
            </div>
            <Transition show={open}
                        unmount={false}
              className='z-40 w-full h-screen flex flex-col absolute left-0 right-0 top-0 bottom-0 pt-20'>
              <Transition.Child
                unmount={false}
                enter="transition-opacity duration-[400] ease-in"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-[400] ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className='absolute left-0 right-0 top-0 bottom-0 z-40 bg-marriage-green' />
              <Transition.Child
                unmount={false}
                enter="transition-transform duration-[600] ease-out"
                enterFrom="translate-y-5"
                enterTo="translate-y-0"
                leave="transition-transform duration-[600] ease-in"
                leaveFrom="translate-y-0"
                leaveTo="translate-y-5"
                className='z-50 w-full flex-grow flex flex-col justify-center text-center text-3xl text-black'>
                <Disclosure.Button
                  as={MyFieldRef}
                  className='py-2'
                  to='/'
                >
                  When & Where
                </Disclosure.Button>
                <Disclosure.Button
                  as={MyFieldRef}
                  className='py-2'
                  to='story'
                >
                  Our Story
                </Disclosure.Button>
              </Transition.Child>
              <Transition.Child
                unmount={false}
                enter="transition-all duration-[600] ease-in"
                enterFrom="translate-y-full opacity-0"
                enterTo="translate-y-0 opacity-100"
                leave="transition-all duration-[600] ease-in"
                leaveFrom="translate-y-0 opacity-100"
                leaveTo="translate-y-full opacity-0"
                className='z-50 w-full mt-8 mb-14'>
                <Disclosure.Button
                  as={MobileRsvpCtaRef} />
              </Transition.Child>
            </Transition>
          </div>
        )
      }}
    </Disclosure>
  )
}